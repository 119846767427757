import moment from "moment";

(function() {
    angular.module('EntrakV5').controller('floorController', floorController);

    function floorController($scope, $rootScope, Service, Api, KEY, APIKEY, $state, $stateParams) {
        console.log('floorController');

        //$stateParams.tenantId
        //$stateParams.floorId
        //$stateParams.gatewayId?
        var caller = Api.createApiCaller();
        $scope.btnStatus = {};

        $rootScope.hideTabs = true;

        $scope.cutMargin = 50;
        $scope.floor = null;
        $scope.floorPlanDataUrl = null;
        $scope.mapModel = { markers: [] };
        $scope.cutFloorModel = { markers: [] };
        $scope.counts = {};
        $scope.gateways = null;
        $scope.selectedGateway = null;
        $scope.searchText = "";

        //init breadcrumbs
        $scope.steps = [
            $rootScope.STEP1, {
                name: '-',
                navigate: function(){
                    $state.go("tenant", { tenantId: $stateParams.tenantId });
                }
            }, {
                name: '-'
            }
        ];

        $scope.applicationTypes = [];
        for (var key in $rootScope.APPLICATION_TYPE){
            if (key !== APIKEY.applicationTypeInv.GATEWAY)
                $scope.applicationTypes.push({ type: $rootScope.APPLICATION_TYPE[key], name: Service.translate("applicationType."+key)});
        }
        $scope.applicationTypes.sort(Service.getSorter("name"));

    /* first load */
        //update breadcrumbs display and get floor
        $rootScope.getLandlordId().then(function(id){
            $rootScope.loadingPage = 1;
            $rootScope.updateTenantStep2Name($scope.steps, $stateParams.tenantId, $stateParams.floorId, true, caller, true).then(function(res){
                $scope.cutFloorModel.buildingId = res.buildingId;
                $scope.cutFloorModel.dns = $rootScope.infoMap[$stateParams.tenantId].dns;
                caller.call(Api.getGatewaysByBuildingId(res.buildingId)).then(function(building){
                    $scope.gatewaySelect.setDataSource(new kendo.data.DataSource({
                        data: building.hubs.reduce(function(array, h){
                            return array.concat(h.gateways);
                        }, [])
                    }));
                    $rootScope.loadingPage--;
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    console.error("unable to get gateway list");
                    $rootScope.loadingPage--;
                    alert(Service.translate("error.generalGetDataFail"));
                });
                console.log("res",res);
                $scope.processFloor(res);
            }, function(err){
                if (err === KEY.ignore)
                    return;
                console.error('invalid floor/tenant id');
                $rootScope.loadingPage--;
                alert(Service.translate("error.generalGetDataFail"));
            });
        });
        $scope.processFloor = function(floor){
            $scope.floor = floor;
            Service.getImgDataUrl(floor.floorPlan, function(data){
                $scope.$apply(function(){
                    $scope.floorPlanDataUrl = data;
                });
            });
            for (var k in APIKEY.applicationTypeInv){
                $scope.counts[k] = { total: 0, online: 0 };
            }
            $scope.gateways = floor.gateways;
            $rootScope.processGwForDeviceMap(floor.gateways, $stateParams.floorId);
            $scope.mapModel.markers = floor.gateways.reduce(function(arr, g){
                g.devices.forEach(function(d){
                    d.gateway = g;
                    d.cssClass = d.status ? 'on' : '';//cannot null for search
                    $scope.counts[d.applicationType].total++;
                    if (d.online)
                        $scope.counts[d.applicationType].online++;
                });
                $scope.counts[APIKEY.applicationType.gateway].total++;
                if (g.online)
                    $scope.counts[APIKEY.applicationType.gateway].online++;
                return arr.concat(g.devices);
            }, floor.gateways.slice());
            $scope.cutFloorModel.markers = $scope.mapModel.markers;

            if ($stateParams.gatewayId) {
                var gw = Service.getArrItem($scope.gateways, $stateParams.gatewayId);
                if (gw)
                    $scope.selectGateway(gw);
            }
        }
    /* first load */

    /* step2 (isFloor) */
        $scope.floorData = {
            callback: function(){
                setTimeout(function(){
                    $scope.editFloorWin.center();
                });
            }
        }
        $scope.editFloorWinOpt = {
            width: "740px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }
        $scope.gatewaySelectOpt = {
            autoWidth: true,
            autoClose: false,
            clearButton: false,
            dataSource: [],
            dataTextField: "serialId",
            dataValueField: "id"
        }

        $scope.editFloor = function(){
            $scope.floorData.id = $scope.floor.id;
            $scope.floorData.name = $scope.floor.name;
            $scope.floorData.alwaysOn = $scope.floor.alwaysOn;
            $scope.floorData.pauseScheduleTime = $scope.floor.pauseSchedule;
            $scope.floorData.pauseTime=moment($scope.floor.pauseTime*1000).format('YYYY-MM-DD HH:mm')

            $scope.floorData.gatewayIds = $scope.floor.gateways.map(function(g){
                return g.id;
            });
            $scope.floorData.init($scope.floor.floorPlan);
            console.log($scope.floorData);
            $scope.editFloorWin.title(Service.translate("tenant.editFloor"));
            setTimeout(function(){
                $scope.editFloorWin.open().center();
            });
        }
        $scope.confirmEditFloor = function(){
            $scope.btnStatus.saving = true;
            var name = $scope.floorData.name;
            $scope.floorPlanDataUrl = null;
            caller.call(Api.updateFloor($scope.floorData.id, name, $scope.floorData.fileObject, $scope.floorData.gatewayIds, $scope.floorData.alwaysOn,$scope.floorData.pauseScheduleTime)).then(function(res){
                $scope.editFloorWin.close();
                $scope.processFloor(res);
                $rootScope.infoMap[res.id] = { name: res.name };
                $rootScope.updateTenantStep2Name($scope.steps, $stateParams.tenantId, $stateParams.floorId, false, caller);
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                alert(Service.translate("error.generalUpdateFloor"));
            });
        }
        $scope.confirmDeleteFloor = function(){
            $scope.btnStatus.saving = true;
            var id = $scope.floorData.id;
            caller.call(Api.deleteFloor(id)).then(function(res){
                // $rootScope.STEP1.navigate();
                $scope.steps[1].navigate();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                alert(Service.translate("error.generalDeleteFail"));
            });
        }

        $scope.confirmText = '';
        $scope.moveOutWinOpt = {
            title: Service.translate("button.tenantMoveOut"),
            width: "540px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }
        $scope.tenantMoveOut = function(){
          $scope.confirmText = '';
          setTimeout(function(){
              $scope.moveOutWin.open().center();
          });
        }
        $scope.confirmMoveOut = function(){
          caller.call(Api.tenantMoveOutFromZone($scope.floor.id)).then(function(res){
              $scope.steps[1].navigate();
          }, function(err){
              if (err === KEY.ignore)
                  return;
              $scope.btnStatus.saving = false;
              alert(Service.translate("error.generalDeleteFail"));
          });
        }

        $scope.showGateways = function(){
            $state.go("gateway", { floorId: $stateParams.floorId, tenantId: $stateParams.tenantId });
        }

        $scope.showDeviceLocation = function(){
            $state.go("deviceLocation", { floorId: $stateParams.floorId, tenantId: $stateParams.tenantId });
        }

        $scope.deviceInfoPopupOpt = {
            anchor: $("#tenant #deviceInfoMap .tooltipAnchor div"),
            origin: "top right",
            position: "top left",
        }
        $scope.selectedMarker = null;
        var deviceCaller;
        $scope.selectMapDevice = function($i){
            $scope.deviceInfoPopup.close();
            $scope.deviceInfoPopup.setOptions({anchor: $("#tenant #deviceInfoMap .tooltipAnchor div")});
            setTimeout(function(){    //wait after popup closed
                $scope.deviceInfoPopup.open();
            }, 200);
            $scope.selectedMarker = $scope.mapModel.markers[$i];

            if ($scope.mapModel.markers[$i].applicationType === APIKEY.applicationType.gateway)
                return;

            if (deviceCaller)
                deviceCaller.cancel();
            deviceCaller = Api.createApiCaller();
            if ($rootScope.haveReadingType($scope.selectedMarker.applicationType)){
                $scope.sensorData = { loading: true };
                deviceCaller.call(Api.getSensorData($scope.selectedMarker.id)).then(function(res){
                    $scope.sensorData = {};
                    if (res.iaqReading){
                        res.iaqReading.forEach(function(data){
                            if (data.measurement === 'humidity'){
                                $scope.sensorData[data.measurement] = Service.numFmt(data.avg*100);
                            } else {
                                $scope.sensorData[data.measurement] = Service.numFmt(data.avg);
                            }
                        });
                    }
                    $scope.sensorData.lux = res.lux;
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $scope.sensorData.loading = false;
                    setTimeout(function(){
                        alert(Service.translate("error.generalGetDataFail"));
                    }, 400);
                });
                if ($scope.selectedMarker.applicationType === APIKEY.applicationType.aircon) {
                    $scope.deltaLoading = true;
                    $scope.isEditTemperature = false;
                    $scope.sectionDelta =[];
                    $scope.controlProfileData = { loading: true, editing: false };
                    deviceCaller.call(Api.getAirconDetail($scope.selectedMarker.id)).then(function(res){
                        const {delta1,delta2,delta3,delta4,delta5,controlProfileId} = res;

                        if (controlProfileId) {
                            $scope.controlProfileData.id = controlProfileId;
                            deviceCaller.call(Api.getControlProfile(controlProfileId)).then(function(res){
                                $scope.controlProfileData = res;
                            }, function(err){
                                if (err === KEY.ignore)
                                    return;
                                $scope.controlProfileData.loading = false;
                                setTimeout(function(){
                                    alert(Service.translate("error.generalGetDataFail"));
                                }, 400);
                            });
                        } else {
                            $scope.controlProfileData = { empty: true };
                        }

                        let sectionDelta=[
                           {origin:delta1.toFixed(1),delta:delta1<0?Math.abs(delta1):delta1},
                           {origin:delta2.toFixed(1),delta:delta2<0?Math.abs(delta2):delta2},
                           {origin:delta3.toFixed(1),delta:delta3<0?Math.abs(delta3):delta3},
                           {origin:delta4.toFixed(1),delta:delta4<0?Math.abs(delta4):delta4},
                           {origin:delta5.toFixed(1),delta:delta5<0?Math.abs(delta5):delta5},
                        ]
                       $scope.deltaLoading = false;
                       $scope.sectionDelta = sectionDelta;
                    $scope.originalSectionDelta = JSON.parse(JSON.stringify(sectionDelta))
                    }, function(err){
                        if (err === KEY.ignore)
                            return;
                        $scope.deltaLoading = false;
                        setTimeout(function(){
                            alert(Service.translate("error.generalGetDataFail"));
                        }, 400);
                    });
                }
            }
            $scope.logData = { loading: true };
            $rootScope.loadDeviceLog($scope.selectedMarker, deviceCaller, function(res) {
                $scope.logData = res;
            }, function(){
                $scope.logData = {};
            });
            // if ($rootScope.canOnOffType($scope.selectedMarker.applicationType)){
            //     $scope.logData.loading = true;
            //     var now = Service.getUnixTimestamp(new Date());
            //     deviceCaller.call(Api.getDeviceLog($scope.selectedMarker.id, now-7*24*3600, now, 5, false)).then(function(res){
            //         var degree = Service.translate("label.degree");
            //         $scope.logData = {};
            //         res.forEach(function(r){
            //             if ($rootScope.haveReadingType(r.applicationType)){
            //                 r.value = Service.numFmt(r.temperature, 1) + degree;
            //             } else if (r.applicationType === APIKEY.applicationType.light){
            //                 r.value = r.level + "%";
            //             }
            //         });
            //         $scope.logData.records = res;
            //     }, function(err){
            //         if (err === KEY.ignore)
            //             return;
            //         $scope.logData.loading = false;
            //         setTimeout(function(){
            //             alert(Service.translate("error.generalGetDataFail"));
            //         }, 400);
            //     });
            // } else if ($scope.selectedMarker.applicationType === APIKEY.applicationType.lightSensor){
            //     $scope.logData.loading = true;
            //     var now = Service.getUnixTimestamp(new Date());
            //     deviceCaller.call(Api.getLightSensorLog($scope.selectedMarker.id, now-24*3600, now)).then(function(res){
            //         $scope.logData = { records: [] };
            //         if (res.luxDatas){
            //             res.luxDatas.reverse();
            //             $scope.logData.records = res.luxDatas;
            //         }
            //     }, function(err){
            //         if (err === KEY.ignore)
            //             return;
            //         $scope.logData.loading = false;
            //         setTimeout(function(){
            //             alert(Service.translate("error.generalGetDataFail"));
            //         }, 400);
            //     });
            // } else if ($scope.selectedMarker.applicationType === APIKEY.applicationType.energyMeter){
            //     $scope.logData.loading = true;
            //     var now = new Date();
            //     var startDate = new Date(now);
            //     startDate.setHours(0, 0, 0, 0);
            //     startDate.setDate(startDate.getDate() - 10);
            //     startDate = Service.getUnixTimestamp(startDate);
            //     now = Service.getUnixTimestamp(now);
            //     deviceCaller.call(Api.getEnergyMeterLog($scope.selectedMarker.serialId, startDate, now)).then(function(res){
            //         $scope.logData = { records: res };
            //         res.reverse();
            //     }, function(err){
            //         if (err === KEY.ignore)
            //             return;
            //         $scope.logData.loading = false;
            //         setTimeout(function(){
            //             alert(Service.translate("error.generalGetDataFail"));
            //         }, 400);
            //     });
            // }
        }

        $scope.confirmUpdateControlProfile = function(){
            $scope.controlProfileData.loading = true;
            $scope.controlProfileData.editing = false;
            var rd = parseInt($scope.controlProfileData.requestDuration);
            if (isNaN(rd) || rd < 0) {
                rd = 0;
            } else if (rd > 240) {
                rd = 240;
            }
            var id = $scope.controlProfileData.id;
            var lrf = $scope.controlProfileData.learnFromRequest;
            var wba = $scope.controlProfileData.warmerBiasApplied;
            caller.call(Api.updateControlProfile(id, $stateParams.tenantId, $scope.controlProfileData.controlProfileName, lrf, wba, rd)).then(function(res){
                if ($scope.controlProfileData?.id === id) {
                    $scope.controlProfileData.loading = false;
                    $scope.controlProfileData.learnFromRequest = lrf;
                    $scope.controlProfileData.warmerBiasApplied = wba;
                    $scope.controlProfileData.requestDuration = rd;
                }
            }, function(err) {
                if (err === KEY.ignore)
                return;
                $scope.controlProfileData.loading = false;
                alert(Service.translate("error.generalUpdate"));
            });
        }

        $scope.selectGateway = function(gateway){
            if ($scope.selectedGateway && $scope.selectedGateway.id === gateway.id){
                $scope.selectedGateway = null;
                $scope.gateways.forEach(function(g){
                    g.cssClass = g.cssClass.replace(" dim", "");
                    if (g.id !== gateway.id){
                        g.devices.forEach(function(d){
                            d.cssClass = d.cssClass.replace(" dim", "");
                        });
                    }
                });
            } else {
                $scope.selectedGateway = gateway;
                $scope.gateways.forEach(function(g){
                    if (g.id === gateway.id){
                        g.cssClass.replace(" dim", "");
                        g.devices.forEach(function(d){
                            d.cssClass = d.cssClass.replace(" dim", "");
                        });
                    } else {
                        if (!g.cssClass.endsWith(' dim'))
                            g.cssClass = g.cssClass + ' dim';
                        g.devices.forEach(function(d){
                            if (!d.cssClass.endsWith(' dim'))
                                d.cssClass = d.cssClass + ' dim';
                        });
                    }
                });
            }
        }
        $scope.editTemperature=function(flag,save=0){
            let _sectionDelta=[],_sectionDeltaObj={}
            if(save){
                let flag=false
                _sectionDelta = $scope.sectionDelta.map((item,index)=>{
                    let delta=document.getElementById('delta'+index)
                    let value = parseFloat(delta.value);
                    if(isNaN(value)||value>3||value<-3){
                        flag=true
                        document.getElementById('delta'+index).style.borderColor='red'
                        document.getElementById('desc').style.visibility='visible'
                        return item
                    }else{
                        value = Math.round(delta.value * 2) / 2
                        _sectionDeltaObj['delta'+(index+1)] = value
                        item.origin=value.toFixed(1)
                        item.delta=value<0?Math.abs(value):value
                        return item
                    }
                })
                if(flag)return
                Api.updateAirconDelta($scope.selectedMarker.id,_sectionDeltaObj).then(res=>{
                    $scope.sectionDelta = _sectionDelta;
                    $scope.originalSectionDelta = JSON.parse(JSON.stringify(_sectionDelta))
                })
            }else{
                $scope.sectionDelta = JSON.parse(JSON.stringify($scope.originalSectionDelta))
            }
            $scope.isEditTemperature=flag
        }
        $scope.getDevicePageLink = function(device) {
          if (device) {
            var devicePagePath = window.location.href.split("/floor")[0] + "/device";
            return devicePagePath + "?serial=" + encodeURIComponent(device.serialId) + "&id=" + device.id;
          }
        }

        $scope.searchDevice = function(){
            var text = ($scope.searchText || '').trim().toUpperCase();
            if (text) {
                $scope.mapModel.markers.forEach(function(m){
                    if (m.serialId.toUpperCase().indexOf(text) > -1 || m.id.toUpperCase().indexOf(text) > -1 || m.remark.toUpperCase().indexOf(text) > -1) {
                        m.cssClass = m.cssClass.replace("hide ", "");
                    } else {
                        if (!m.cssClass.startsWith("hide "))
                            m.cssClass = "hide " + m.cssClass;
                    }
                });
            } else {
                $scope.mapModel.markers.forEach(function(m){
                    m.cssClass = m.cssClass.replace("hide ", "");
                });
            }
        }

        $scope.registerAllDevices = function(){
            $scope.btnStatus.saving = true;
            var map = {};
            var calls = [];
            $scope.gateways.forEach(function(g){
                map[g.hubId] = g.hubId;
                calls.push(Api.scanDevices(g.id, true));
            });
            Object.keys(map).forEach(function(hubId){
                calls.push(Api.scanGateways(hubId));
            });
            caller.call(calls).then(function(res){
                $scope.btnStatus.saving = false;
                for (var i=0; i<res.length; i++){
                    if (!res[i].success){
                        alert(Service.translate("error.generalScanFail"));
                        return;
                    }
                }
                $rootScope.infoPopup.show("button.regDevice", "label.scanDesc");
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                alert(Service.translate("error.generalScanFail"));
            });
        }
    /* step2 (isFloor) */

    /* cut floor */
        $scope.showMapCut = false;
        $scope.cutFloorWinOpt = {
            title: Service.translate("button.cutFloor"),
            width: "1000",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            },
            activate: function(){
                $scope.$apply(function(){
                    $scope.showMapCut = true;
                });
                $(".mapCutHolder, #cutFloorPlan").height(Math.max(600, window.innerHeight*0.8-250) + "px");
                $scope.cutFloorWin.setOptions({ width: Math.max(1000, window.innerWidth*0.8) });
                $scope.cutFloorWin.center();
            },
            close: function(){
                if (!$scope.$$phase) {
                    $scope.$apply(function(){
                        $scope.showMapCut = false;
                    });
                } else {
                    $scope.showMapCut = false;
                }
            }
        }

        $scope.cutFloor = function(){
            $scope.cutFloorModel.name = '';
            setTimeout(function(){
                $scope.cutFloorWin.center().open();
            });
        }

        $scope.confirmCutFloor = function(){
            $scope.btnStatus.saving = true;
            $scope.cutFloorModel.getSelectedArea(function(blob, selectedMarkers, x, y){
                x -= $scope.cutMargin;
                y -= $scope.cutMargin;
                caller.call(Api.duplicateFloor($scope.cutFloorModel.buildingId, $scope.cutFloorModel.dns, $scope.cutFloorModel.name, blob, selectedMarkers.map(function(m){
                    return {
                        id: m.location.id,
                        x: Math.round(m.location.x - x),
                        y: Math.round(m.location.y - y)
                    }
                }))).then(function(res){
                    $scope.cutFloorWin.close();
                    $scope.btnStatus.saving = false;
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $scope.btnStatus.saving = false;
                    alert(Service.translate("error.generalCreateFloor"));
                });
            });
        }
    /* cut floor */

        $scope.$on('$destroy', function() {
            console.log("floorController destroy");
            caller.cancel();
        });
    }
})();
