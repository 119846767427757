import {ApolloClient} from 'apollo-client';
import {setContext} from "apollo-link-context";
import {InMemoryCache} from 'apollo-cache-inmemory';
import {createUploadLink} from 'apollo-upload-client';
import gql from "graphql-tag";
import {v4 as uuidv4} from 'uuid';

(function() {
    'use strict';

    angular.module('EntrakV5').service('Api', ['$http', '$q', '$state', 'Service', 'KEY', 'APIKEY', 'URL', 'REFRESH_URL', 'IS_LOCAL', api]);

    function api($http, $q, $state, Service, KEY, APIKEY, URL, REFRESH_URL, IS_LOCAL) {
        console.log('api service');

    // graphQL
        var client = null;
        function initApolloClient(signInMethod, idToken, forceInit) {
            var authLink = setContext(function(_, opt) {
              var tmp = {
                headers: {
                  ...opt.headers,
                  "x-request-id": uuidv4(),
                },
              }
              if (idToken)
                tmp.headers.authorization = "Bearer " + idToken;
              tmp.headers["authorization-method"] = signInMethod;

              return tmp;
            });

            client = new ApolloClient({
                link: authLink.concat(createUploadLink({ uri: `${URL}api/gql`, credentials: 'include' })),
                cache: new InMemoryCache()
            });
        }

        function toApolloQuery(queryStr, ...fragmentStr){
            return client.query({
                fetchPolicy: 'no-cache',
                query: gql('query {' + queryStr + '}' + fragmentStr.join(""))
            });
        }
        function toApolloMutation(mutationStr, ...fragmentStr){
            return client.mutate({
                mutation: gql('mutation {' + mutationStr + '}' + fragmentStr.join(""))
            });
        }
        function toApolloMutationWithFile(mutationStr, file1, ...fragmentStr){
            return client.mutate({
                variables: {
                    file1: file1
                },
                mutation: gql('mutation($file1: Upload!) {' + mutationStr + '}' + fragmentStr.join(""))
            });
        }
        function toApolloMutationWith2File(mutationStr, file1, file2, ...fragmentStr){
            return client.mutate({
                variables: {
                    file1: file1,
                    file2: file2,
                },
                mutation: gql('mutation($file1: Upload!, $file2: Upload!) {' + mutationStr + '}' + fragmentStr.join(""))
            });
        }

        //QueryParam class, new it
        function QueryParam(){
            this.value = "";
        }
        QueryParam.prototype.toSafeStr = function(val){
            if (typeof val != "string")
                val += "";
            var safeStr = val.replace(/\\|"/g, '');
            if (safeStr !== val)
                console.warn("unsafe str removed", val);
            return safeStr;
        }
        QueryParam.prototype.val = function(paramName, val){
            if (paramName){
                this.value += paramName + ':' + val + ' ';
            } else {
                this.value += val + ' ';
            }
            return this;
        }
        QueryParam.prototype.str = function(paramName, val){
            if (val == null){
                val = "";
            } else {
                val = this.toSafeStr(val);
            }
            if (paramName){
                this.value += paramName + ':"' + val + '" ';
            } else {
                this.value += '"' + val + '" ';
            }
            return this;
        }
        QueryParam.prototype.enum = function(paramName, val){
            val = this.toSafeStr(val);
            return this.val(paramName, val);
        }
        QueryParam.prototype.obj = function(paramName, val, objectTypes){    //objectTypes: {str:[fieldName], val:[fieldName], enum:[fieldName], arr:[{fieldName, fieldType, objectTypes}, obj:[{fieldName, objectTypes}]}
            if (paramName){
                paramName = paramName + ":";
            } else {
                paramName = "";
            }

            if (!val){
                if (val !== undefined)
                    this.value += paramName + 'null ';
                return this;
            }

            this.value += paramName + '{ ';
            for (var type in objectTypes){
                if (objectTypes.hasOwnProperty(type)){
                    var list = objectTypes[type];
                    for (var i=0; i<list.length; i++){
                        var field = list[i];
                        if (type === 'arr'){
                            var arrVal = val[field.fieldName];
                            if (arrVal !== undefined){
                                this.arr(field.fieldName, arrVal, field.fieldType, field.objectTypes);
                                this.value += ",";
                            }
                        } else if (type === 'obj'){
                            var objVal = val[field.fieldName];
                            if (objVal !== undefined){
                                this.obj(field.fieldName, objVal, field.objectTypes);
                                this.value += ",";
                            }
                        } else {
                            var typeVal = val[field];
                            if (typeVal !== undefined){
                                this[type](field, typeVal);
                                this.value += ",";
                            }
                        }
                    }
                }
            }
            if (this.value.slice(-1) === ',')
                this.value = this.value.slice(0, -1);
            this.value += '} ';
            return this;
        }
        //2d array not supported yet
        QueryParam.prototype.arr = function(paramName, val, fieldType, objectTypes){    //fieldType: {str, val, enum, obj}, objectTypes: {str:[fieldName], val:[fieldName], enum:[fieldName], obj:[{objectTypes}}
            if (paramName){
                paramName = paramName + ":";
            } else {
                paramName = "";
            }

            if (!val){
                this.value += paramName + '[] ';
                return this;
            }

            this.value += paramName + '[ ';
            for (var i=0; i<val.length; i++){
                if (i != 0)
                    this.value += ',';
                this[fieldType](null, val[i], objectTypes);
            }
            this.value +='] ';
            return this;
        }
        QueryParam.prototype.get = function(){
            return "(" + this.value.trim() + ")";
        }
    // graphQL

    // interceptor
        //should call bind to this function
        function apolloSuccessInterceptor(key, res){
            if (res.errors || !res.data){
                apolloErrorInterceptor(res);
            } else {
                res = res.data;
                return key && res.hasOwnProperty(key) ? res[key] : res;
            }
        }
        function apolloErrorInterceptor(res) {
            // if (res.networkError && res.networkError.result && res.networkError.result.errors && res.networkError.result.errors[0] && res.networkError.result.errors[0].extensions.code === APIKEY.unauthenticated){
            if (res.networkError && (res.networkError.statusCode === 401 || res.networkError.result && res.networkError.result.statusCode === 401)){
                window.location = REFRESH_URL + encodeURIComponent(window.location.href);
            } else {
                console.error("api call fail", res);
                throw res;
            }
        }
    // interceptor

    //query
        var simpleUserFrag = 'fragment SimpleProfile on Profile { id email firstName lastName inviteStatusV2 landlordId tenantId aclRoles }';
        var simpleLandlordFrag = 'fragment SimpleLandlord on Landlord { id name }';
        var simpleTenantFrag = 'fragment SimpleTenant on Tenant { id name dns }';
        var simpleCityFrag = 'fragment SimpleCity on City { id country city timezone }';
        var fullCityFrag = 'fragment FullCity on City { id country city timezone provider locationCode holidayCode }';

        var simpleGatewayFrag = 'fragment SimpleGateway on Gateway { id serialId firmwareVersion }';
        var simpleHubFrag = 'fragment SimpleHub on Hub { id serialId enabled version cargoList }';
        var hubGatewayFrag = 'fragment HubGateway on Hub { ...SimpleHub gateways { ...SimpleGateway } }' + simpleHubFrag + simpleGatewayFrag;
        var hubBuildingFrag = 'fragment HubBuilding on Hub { ...SimpleHub buildings { id name } }' + simpleHubFrag;

        var currentUserObj = '{ id firstName lastName landlordId aclRoles }';

        var simpleUserObj = '{ ...SimpleProfile }';
        var simpleUserWithTenantObj = '{ ...SimpleProfile tenant { ...SimpleTenant } }';
        var simpleUserForDownloadObj = '{ id email firstName lastName tenantId roleName tenantName }';

        var cityObj = '{ ...SimpleCity }';
        var fullCityObj = '{ ...FullCity }';
        var rankDifferentDimensions = '{ zoneId,cityId,tenantId,buildingId,tenantName,zoneName,currentAvgTemperature,currentByTemperature,outdoorTemperature,rankingInCity,rankingInBuilding,rankingInTenant,rankingMethod }';
        var simpleHubObj = '{ ...SimpleHub }';

        var locationObj = '{ id x y }';
        var gatewayLocObj = '{ zoneId x y }';
        var deviceDefaultObj = '{ defaultTemperature defaultDimming }';
        var deviceObj = '{ id serialId remark deviceType applicationType online dimmable defaultFanSpeed }';
        var detailDeviceObj = '{ id serialId gatewayId status remark version deviceType provider applicationType online dimmable fanSpeed defaultFanSpeed fanSpeedProfileId location locationObj defaultValue deviceDefaultObj }'.replace(/locationObj/g, locationObj).replace(/deviceDefaultObj/g, deviceDefaultObj);
        var fullDeviceObj = '{ id serialId gatewayId status remark version deviceType provider applicationType online dimmable fanSpeed defaultFanSpeed fanSpeedProfileId thermoProfile { heatIndexOutSchedule logicOutSchedule heatIndexInSchedule logicInSchedule } co2Profile { threshold logic } location locationObj defaultValue deviceDefaultObj }'.replace(/locationObj/g, locationObj).replace(/deviceDefaultObj/g, deviceDefaultObj);
        var simpleGatewayObj = '{ id serialId enabled firmwareVersion hubId zoneIds lastBackupDate provider remark online count { offline light aircon smartlight button iaq }  }';
        var displayGatewayObj = "{ id serialId }";
        var gatewayObj = '{ id serialId firmwareVersion remark online devices deviceObj }'.replace(/deviceObj/g, deviceObj);
        var fullGatewayObj = '{ id serialId enabled firmwareVersion hubId provider remark online gatewayLocations { zoneId x y } devices detailDeviceObj zones { id buildingId name floorPlan } }'.replace(/detailDeviceObj/g, detailDeviceObj);
        var hubBuildingObj = '{ ...HubBuilding remark online }';

        var gatewayBackupObj = '{ gatewayId serial versions { versionId lastModified } }';

        var controlProfileObj = '{ id controlProfileName learnFromRequest warmerBiasApplied requestDuration }';

        var sensorDataObj = '{ iaqReading { measurement avg } lux }';
        var lightSensorDataObj = '{ luxDatas { lux timestamp } }';
        var temperatureSensorDataObj = '{ deviceId temperatures { value timestamp } }';

        var dataPointObj = '{ id status name scaler refId meterId hierarchyDatapoint { refId dataType id } standard mean }';
        var dataWaterPointObj = '{ id name scaler refId meterId hierarchyDatapoint { refId dataType id } isEnabled meterId remark modbus { slaveId registerId registerType} bacnet { objectId objectType } }';
        var meterObj = '{ id serial tenantId status refId failedCounts reportLastSent lastOnlineAt description remark tariff hasKva dataflowMode dataType meterType dataPoints dataPointObj egaugeMeter { xmlUrl } bmsMeter { type dataTransmissionUnit } acrelMeter { dataTopic heartbeatTopic payloadParser } }'.replace(/dataPointObj/g, dataPointObj);
        var waterMeterObj = '{ id serial tenantId description remark tariff meterType isEnabled dataPoints dataWaterPointObj }'.replace(/dataWaterPointObj/g, dataWaterPointObj);
        var meterWithoutDpObj = '{ id serial tenantId status refId failedCounts reportLastSent lastOnlineAt tariff remark description hasKva dataflowMode dataType meterType egaugeMeter { xmlUrl } bmsMeter { type dataTransmissionUnit } acrelMeter { dataTopic heartbeatTopic payloadParser } }';
        var waterMeterWithoutDpObj = '{ id serial tenantId description tariff remark meterType isEnabled }';
        var dataPointDataObj = '{ datapointId values { time value } }';
        var hierarchyDataPointObj = '{ id refId name dataId dataType }';
        var hierarchyObj = '{ root { id type name childrenCount povUnit children { id type name childrenCount datapoint hierarchyDataPointObj } datapoint hierarchyDataPointObj } }'.replace(/hierarchyDataPointObj/g, hierarchyDataPointObj);
        var hierarchyObjLocations = '{ id type name childrenCount }';
        var virtualDataPointObj = '{ id name expression refId hierarchyDatapoint { refId  dataType  id } status }';
        var waterVirtualDataPointObj = virtualDataPointObj;

        var hierarchyPovObj = '{ id name povUnit visible accessType zoneReference }';
        var hierarchySiteObj = '{ id name cityId nodeReferenceId address accessType }';

        var occupiedAreaObj = '{ id name povId tenantId remark }';
        var occupiedAreaAndDateObj = '{ id tenantId povId name remark latestArea { id area effectiveDate } }';
        var occupiedAreaDateObj = '{ id area effectiveDate }';
        var simplePovObj = '{ id name }';

        var airconDetailObj = '{ deviceId fanSpeedProfileId defaultTemperature defaultFanSpeed delta1 delta2 delta3 delta4 delta5 roomTemperature controlProfileId }';
        var airconDeltaEventObj = '{ timestamp sessions { coolerRequests warmerRequests delta } }';

        var displayFloorObj = '{ id buildingId name }';
        var simpleFloorObj = '{ id name alwaysOn buildingId floorPlan }';
        var floorObj = '{ id name alwaysOn buildingId floorPlan gateways simpleGatewayObj }'.replace(/simpleGatewayObj/g, simpleGatewayObj);
        var fullFloorObj = '{ id buildingId name alwaysOn floorPlan gateways fullGatewayObj pauseSchedule pauseTime }'.replace(/fullGatewayObj/g, fullGatewayObj);
        var simpleTenantObj = '{ ...SimpleTenant }';
        var oauthObj = '{ provider code }';
        var tenantOAuthObj = '{ oauths oauthObj }'.replace(/oauthObj/g, oauthObj);
        var tenantObj = '{ id name dns defaultTimezone apiKey cognitoUserpool logo code enableTep enableEms widget { airconEnable lightingEnable greenScoreRatingEnable energyConsumptionEnable indoorAirQualityEnable } hasIaq hasSolar oauths oauthObj maxExtensionHours defaultExtensionMinutes enableFloorPlanSelection serviceCredentials { provider providerTenantCode } zones simpleFloorObj }'.replace(/simpleFloorObj/g, simpleFloorObj).replace(/oauthObj/g, oauthObj);
        var simpleBuildingObj = '{ id name city cityObj latitude longitude hubs { ...SimpleHub } tenants simpleTenantObj }'.replace(/simpleTenantObj/g, simpleTenantObj).replace(/cityObj/g, cityObj);
        var buildingObj = '{ id name city cityObj hubs { ...HubGateway } tenants tenantObj }'.replace(/tenantObj/g, tenantObj).replace(/cityObj/g, cityObj);
        var displayBuildingObj = '{ id name buildings { id name } }';
        var tenantPageBuildingObj = '{ id name buildings { id name city cityObj zones { id name buildingId } hubs { ...HubGateway } } }'.replace(/cityObj/g, cityObj);
        var displayTenantObj = '{ id name enableEms enableTep code }';

        var buildingGatewayObj = '{ id hubs { ...HubGateway } }';
        var simpleLandlordObj = '{ ...SimpleLandlord }';
        var buildingPageObj = '{ ...SimpleLandlord buildings simpleBuildingObj }'.replace(/simpleBuildingObj/g, simpleBuildingObj);
        var distributorDetailPageObj = '{ ...SimpleLandlord users { ...SimpleProfile } buildings { id name city cityObj tenants { ...SimpleTenant zones { id } } } }'.replace(/cityObj/g, cityObj);

        var deviceLogObj = '{ applicationType level temperature status time }';
        var offlineEventObj = '{ time online }';

        var energyMeterLogObj = "{ time kwh }";

        var deviceActivityObj = '{ deviceId deviceSerial deviceActivity { status time } }';
        var deviceOnlineObj = '{ deviceId deviceSerial deviceOnline { online time } }';

        var devicePageObj = '{ zones simpleFloorObj tenants { id name zones { id } } gateway { id serialId online } hub { id serialId online } buildings { id name } locations { id x y zoneId isCooling isWarming } device fullDeviceObj }'.replace(/fullDeviceObj/g, fullDeviceObj).replace(/simpleFloorObj/g, simpleFloorObj);

        var fanSpeedProfileObj = '{ id description deviceType provider }';

        var timeslotFullObj = '{ id weekday start { hour minute action } end { hour minute action } lastTrigger tenantId timetableId timetableName timezone }';

        var timetableHolidayObj = '{ timetableId name }';

        var tariffObj = "{ id name remark currency }";

        var roleOptionObj = "{ roles }";

        var queueStatusObj = "{ name messagesUnacknowledged messagesReady total }";

        var testFormulaObj = "{ expression result }";

        var consumptionObj = "{ id timeConsuming totalValue values { ts value number } }";

        var iaqInfoObj = "{ timestamp temperature humidity co2 pm25 tvoc }";

        var Struct = {
            building: {
                str: ["name", "cityId", "address"],
                val: ["latitude", "longitude"]
            },
            tenant: {
                str: ["name", "dns", "code", "defaultTimezone", "cognitoUserpool"],
                val: ["maxExtensionHours", "defaultExtensionMinutes", "enableFloorPlanSelection", "enableEms", "enableTep", "hasSolar", "hasIaq", "logo"],
                obj: [{
                    fieldName: "widget",
                    objectTypes: {
                        val: ["airconEnable", "lightingEnable", "greenScoreRatingEnable", "energyConsumptionEnable", "indoorAirQualityEnable"],
                    }
                }]
            },
            zone: {
                str: ["name", "domain"],
                val: ["floorPlan", "alwaysOn","pauseSchedule"],
                arr: [{ fieldName: "gateways", fieldType: "str" }],
            },
            duplicateZoneInput: {
                str: ["name", "dns", "buildingId"],
                val: ["floorPlan"],
                arr: [{
                    fieldName: "locations",
                    fieldType: "obj",
                    objectTypes: {
                        str: ["id"],
                        val: ["x", "y"]
                    }
                }],
            },
            location: {
                val: ['x', 'y']
            },
            profileQuery: {
                str: ["id"],
                val: ["adminOnly"],
                enum: ["type"],
            },
            landlord: {
                arr: [{
                    fieldName: "aclRoles",
                    fieldType: "enum",
                }]
            },
            deviceInfo: {
                val: ["defaultTemperature", "defaultDimming"],
                str: ["remark", "applicationType", "fanSpeedProfileId"],
                enum: ["defaultFanSpeed"]
            },
            hub: {
                str: ["remark"],
                val: ["enabled"],
                arr: [{ fieldName: "buildings", fieldType: "str" }],
            },
            deltas: {
                val: ["delta1",'delta2','delta3','delta4','delta5'],
            },
            gateway: {
                str: ["remark"],
                val: ["enabled"]
            },
            deviceEvent: {
                val: ["startTime", "endTime", "limit", "statusIdent"],
                str: ["id"],
                enum: ["by"]
            },
            offlineEvent: {
                str: ["id"],
                val: ["startTime"],
                enum: ["type"]
            },
            city: {
                str: ['country', 'city', 'timezone', 'locationCode', 'holidayCode'],
                enum: ["provider"]
            },
            meter: {
                str: ['tenantId', 'serial', 'xmlUrl', 'description', 'bmsType', 'dataTransmissionUnit', 'id', 'remark', 'tariffId'],
                val: ['status', 'hasKva'],
                enum: ['dataflowMode', 'dataType', 'meterType'],
                obj: [{
                    fieldName: "egaugeMeter",
                    objectTypes: {
                        str: ["xmlUrl"],
                    }
                }, {
                    fieldName: "bmsMeter",
                    objectTypes: {
                        enum: ["type", "dataTransmissionUnit"]
                    }
                }, {
                    fieldName: "acrelMeter",
                    objectTypes: {
                        str: ["dataTopic", "heartbeatTopic", "payloadParser"],
                    }
                }]
            },
            waterMeter: {
                str: ['tenantId', 'serial', 'description', 'id','remark', 'tariffId'],
                val: ['isEnabled'],
                enum: ['meterType'],
            },
            dataPoint: {
                str: ['meterId', 'name'],
                val: ['scaler'],
                enum: ['status', 'scalerInHub', 'isGenerator']
            },
            waterDataPoint: {
                str: ['meterId', 'name','remark'],
                val: ['scaler','isEnabled'],
                obj:[{
                    fieldName: "modbus",
                    objectTypes: {
                        val: ["slaveId",'registerId'],
                        enum:['registerType']
                    }
                }, {
                    fieldName: "bacnet",
                    objectTypes: {
                        val: ["objectId"],
                        enum:["objectType"]
                    }
                }]
            },
            virtualDataPoint: {
                str: ["tenantId", "name", "expression"],
                enum: ["status"]
            },
            hierarchyInput: {
                str: ["id",'povUnit'],
                enum: ["type"],
            },
            bindDataPointInput: {
                str: ["name", "dataId"],
                enum: ["dataType"],
                obj: [{
                    fieldName: "parent",
                    objectTypes: {
                        str: ["id"],
                        enum: ["type"]
                    }
                }]
            },
            unbindDataPointParent: {
                str: ["id"],
                enum: ["type"],
            },
            createChildInput: {
                str: ["name", "cityId", "address", "nodeReferenceId"],
                enum: ["accessType"],
                obj: [{
                    fieldName: "parent",
                    objectTypes: {
                        str: ["id"],
                        enum: ["type"]
                    }
                }],
            },
            updateHierarchyPovInput: {
                str: ["name", "zoneReference"],
                enum: ["povUnit", "visible", "accessType"]
            },
            nodeNameInput: {
                str: ["id", "name"],
                enum: ["type"]
            }
        }
    //query

        return {

            initApolloClient: initApolloClient,

            // need to wrap apollo api call by $q or $apply, otherwise angularjs 2-way binding may not work
            createApiCaller: function(){
                return {
                    // need to wrap apollo api call by this function (or $q, $apply), otherwise angularjs 2-way binding may not work
                    call: function(promises, skipActiveCheck){
                        var isArr = Array.isArray(promises);
                        var that = this;
                        return $q.all(isArr ? promises : [promises]).then(function(resList){
                            if (that._isActive || skipActiveCheck){
                                return isArr ? resList : resList[0];
                            } else {
                                return $q.reject(KEY.ignore);
                            }
                        }, function(err){
                            return $q.reject((that._isActive || skipActiveCheck) ? err : KEY.ignore);
                        });
                     },
                    cancel: function(){
                        this._isActive = false;
                    },
                    _isActive: true
                }
            },

            logout: function(){
              if (IS_LOCAL) {
                Service.storageDelete("et_method");
                Service.storageDelete("et_it");
                Service.storageDelete("et_rt");
              } else {
                Service.deleteCookie("et_method");
                Service.deleteCookie("et_it");
                Service.deleteCookie("et_rt");
              }
              window.location = URL + 'auth/signout?page=landlord';
            },

            getCities: function(){
                var queryName = 'getCities';
                var str = queryName + cityObj;
                return toApolloQuery(str, simpleCityFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getFullCities: function(){
                var queryName = 'getCities';
                var str = queryName + fullCityObj;
                return toApolloQuery(str, fullCityFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getRankDifferentDimensions:function(year,weekOfYear){
                var queryName = 'getRankDifferentDimensions';
                var str = queryName + rankDifferentDimensions;
                var selector = new QueryParam().val("year", year).val("weekOfYear", weekOfYear);
                var str = queryName + selector.get() + rankDifferentDimensions;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            createCity: function(country, city, timezone, provider, locationCode, holidayCode){
                var queryName = 'createCity';
                var selector = new QueryParam().obj("city", {
                    country: country,
                    city: city,
                    timezone: timezone,
                    provider: provider,
                    locationCode: locationCode,
                    holidayCode: holidayCode
                }, Struct.city);
                var str = queryName + selector.get() + fullCityObj;
                return toApolloMutation(str, fullCityFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            updateCity: function(id, country, city, timezone, provider, locationCode, holidayCode){
                var queryName = 'updateCity';
                var selector = new QueryParam().str("id", id).obj("city", {
                    country: country,
                    city: city,
                    timezone: timezone,
                    provider: provider,
                    locationCode: locationCode,
                    holidayCode: holidayCode
                }, Struct.city);
                var str = queryName + selector.get() + fullCityObj;
                return toApolloMutation(str, fullCityFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            deleteCity: function(id){
                var queryName = 'deleteCity';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getBuilding: function(id){
                var queryName = 'getBuilding';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get() + "{ id name }";
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getBuildings: function(){
                var queryName = 'getLandlords';
                var str = queryName + displayBuildingObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getLandlordBuildings: function(){//only buildings in current landlord
                var queryName = 'getLandlord';
                var str = queryName + tenantPageBuildingObj;
                return toApolloQuery(str, hubGatewayFrag, simpleCityFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getBuildingPage: function(){
                var queryName = 'getLandlord';
                var str = queryName + buildingPageObj;
                return toApolloQuery(str, simpleLandlordFrag, simpleTenantFrag, simpleHubFrag, simpleCityFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            createBuilding: function(name, cityId, address, latitude, longitude){
                var queryName = 'createBuilding';
                var selector = new QueryParam().obj("building", {
                    name: name,
                    cityId: cityId,
                    address: address,
                    latitude: latitude,
                    longitude: longitude
                }, Struct.building);
                var str = queryName + selector.get() + simpleBuildingObj;
                return toApolloMutation(str, simpleTenantFrag, simpleHubFrag, simpleCityFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            updateBuilding: function(id, name, cityId, latitude, longitude){
                var queryName = 'updateBuilding';
                var selector = new QueryParam().str("id", id).obj("building", {
                    name: name,
                    cityId: cityId,
                    latitude: latitude,
                    longitude: longitude
                }, Struct.building);
                var str = queryName + selector.get() + simpleBuildingObj;
                return toApolloMutation(str, simpleTenantFrag, simpleHubFrag, simpleCityFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            deleteBuilding: function(id){
                var queryName = 'deleteBuilding';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getTenants: function(tepOnly, emsOnly){
              var queryName = "getTenantsByLandlord";
              var selector = new QueryParam().val("onlyTep", tepOnly).val("onlyEms", emsOnly);
              var str = queryName + selector.get() + tenantObj;
              return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getDisplayTenants: function(tepOnly, emsOnly){
              var queryName = "getTenantsByLandlord";
              var selector = new QueryParam().val("onlyTep", tepOnly).val("onlyEms", emsOnly);
              var str = queryName + selector.get() + displayTenantObj;
              return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            // getTenantPage: function(){
            //     var queryName = 'getLandlord';
            //     var str = queryName + tenantPageObj;
            //     return toApolloQuery(str, simpleLandlordFrag, hubGatewayFrag, simpleCityFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            // },
            getTenant: function(id){
                var queryName = 'getTenant';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get() + simpleTenantObj;
                return toApolloQuery(str, simpleTenantFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            createTenantAndFloor: function(buildingId, tenantName, timezone, domain, userpool, maxExtendHrs, defaultExtendMins, enableFloorPlanSelection, widget, floorName, floorPlanFile, gatewayIds,
              alwaysOn, enableEms, logoFile, hasIaq, hasSolar, tenantCode){
                var queryName = 'createTenantWithZone';
                var tenantInput = {
                    enableTep: true,
                    enableEms: enableEms,
                    name: tenantName,
                    dns: domain,
                    maxExtensionHours: maxExtendHrs,
                    defaultExtensionMinutes: defaultExtendMins,
                    enableFloorPlanSelection: enableFloorPlanSelection,
                    defaultTimezone: timezone,
                }
                if (userpool)
                  tenantInput.cognitoUserpool = userpool;
                if (widget)
                  tenantInput.widget = widget;
                if (logoFile)
                  tenantInput.logo = "$file2";
                if (tenantCode)
                  tenantInput.code = tenantCode;
                if (hasIaq != null)
                  tenantInput.hasIaq = hasIaq;
                if (hasSolar != null)
                  tenantInput.hasSolar = hasSolar;
                var selector = new QueryParam().str("buildingId", buildingId).obj("tenant", tenantInput, Struct.tenant).obj("zone", {
                    name: floorName,
                    floorPlan: "$file1",
                    gateways: gatewayIds,
                    alwaysOn: alwaysOn
                }, Struct.zone);
                var str = queryName + selector.get() + tenantObj;
                if (tenantInput.logo) {
                  return toApolloMutationWith2File(str, floorPlanFile, logoFile).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
                } else {
                  return toApolloMutationWithFile(str, floorPlanFile).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
                }
            },
            createTenant: function(name, timezone, domain, userpool, logoFile, enableTep, enableEms, tenantCode, hasIaq, hasSolar, maxExtendHrs, defaultExtendMins, enableFloorPlanSelection, widget){
                var queryName = 'createTenant';
                var tenantInput = {
                    enableTep: enableTep,
                    enableEms: enableEms,
                    name: name,
                    dns: domain,
                    defaultTimezone: timezone,
                }
                if (userpool)
                  tenantInput.cognitoUserpool = userpool;
                if (widget)
                  tenantInput.widget = widget;
                if (logoFile)
                  tenantInput.logo = "$file1";
                if (enableTep) {
                    tenantInput.maxExtensionHours = maxExtendHrs;
                    tenantInput.defaultExtensionMinutes = defaultExtendMins;
                    tenantInput.enableFloorPlanSelection = enableFloorPlanSelection;
                }
                if (enableEms) {
                  tenantInput.code = tenantCode;
                  tenantInput.hasIaq = hasIaq;
                  tenantInput.hasSolar = hasSolar;
                }

                var selector = new QueryParam().obj("tenant", tenantInput, Struct.tenant);
                var str = queryName + selector.get() + tenantObj;
                if (tenantInput.logo) {
                  return toApolloMutationWithFile(str, logoFile).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
                }else  {
                  return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
                }
            },
            updateTenant: function(id, name, timezone, logoFile, enableTep, enableEms, tenantCode, hasIaq, hasSolar, maxExtendHrs, defaultExtendMins, enableFloorPlanSelection, widget){
                var queryName = 'updateTenant';
                var tenantInput = {
                    enableTep: enableTep,
                    enableEms: enableEms,
                    name: name,
                    defaultTimezone: timezone,
                }
                if (widget)
                  tenantInput.widget = widget;
                if (logoFile)
                  tenantInput.logo = "$file1";
                if (enableTep) {
                    tenantInput.maxExtensionHours = maxExtendHrs;
                    tenantInput.defaultExtensionMinutes = defaultExtendMins;
                    tenantInput.enableFloorPlanSelection = enableFloorPlanSelection;
                }
                if (enableEms) {
                  tenantInput.code = tenantCode;
                  tenantInput.hasIaq = hasIaq;
                  tenantInput.hasSolar = hasSolar;
                }

                var selector = new QueryParam().str("id", id).obj("tenant", tenantInput, Struct.tenant);
                var str = queryName + selector.get() + '{ id }';//tenantObj; dont get any field here since it return all floors of the tenant even though they under different landlord, call reload
                if (tenantInput.logo) {
                  return toApolloMutationWithFile(str, logoFile).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
                } else {
                  return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
                }
            },
            deleteTenant: function(id){
                var queryName = 'deleteTenant';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            tenantMoveOutFromZone: function(zoneId) {
                var queryName = 'removeAllNodeByZone';
                var selector = new QueryParam().str("zoneId", zoneId);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getTenantByZone: function(zoneId) {
                var queryName = 'getTenantByZoneId';
                var selector = new QueryParam().str("zoneId", zoneId);
                var str = queryName + selector.get() + "{ id }";
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            createDummyHubGw: function(id){
                var queryName = 'createVirtualGatewayHub';
                var selector = new QueryParam().str("tenantId", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getFloor: function(id, needDevice, needGateway){
                var queryName = 'getZone';
                var selector = new QueryParam().str("id", id);
                if (needDevice){
                    var str = queryName + selector.get() + fullFloorObj;
                } else if (needGateway){
                    var str = queryName + selector.get() + floorObj;
                } else {
                    var str = queryName + selector.get() + simpleFloorObj;
                }
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getAirconDeltaEvents: function(deviceId, startTime, endTime){
                let queryName = 'getAcDeltaEvents';
                let selector = new QueryParam().str('deviceId',deviceId).val('startTime',startTime).val('endTime',endTime).val('timezoneOffsiteMinute', (new Date().getTimezoneOffset() * -1));
                var str = queryName + selector.get() + airconDeltaEventObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            // getAirconDetail
            getAirconDetail:function(deviceId){
                let queryName = 'getAirconDetail';
                let selector = new QueryParam().str('deviceId',deviceId);
                var str = queryName + selector.get() + airconDetailObj
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            // updateAirconDelta
            updateAirconDelta:function(deviceId,deltas){
                let queryName = 'updateAirconDelta';
                let selector = new QueryParam().str('deviceId',deviceId).obj('deltas',deltas,Struct.deltas);
                var str = queryName + selector.get() + airconDetailObj
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getFloors: function(ids){
                var queryName = 'getZonesByIds';
                var selector = new QueryParam().arr("ids", ids, "str");
                var str = queryName + selector.get() + displayFloorObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            createFloor: function(buildingId, domain, name, floorPlanFile, gatewayIds, alwaysOn){
                var queryName = 'createZone';
                var selector = new QueryParam().str("buildingId", buildingId).str("dns", domain).obj("zone", {
                    name: name,
                    floorPlan: "$file1",
                    gateways: gatewayIds,
                    alwaysOn: alwaysOn
                }, Struct.zone);
                var str = queryName + selector.get() + simpleFloorObj;
                return toApolloMutationWithFile(str, floorPlanFile).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            updateFloor: function(id, name, floorPlanFile, gatewayIds, alwaysOn,pauseSchedule){
                var queryName = 'updateZone';
                var zoneObj = { name: name, gateways: gatewayIds, alwaysOn: alwaysOn,pauseSchedule:pauseSchedule };

                console.log("zoneObj",zoneObj);
                if (floorPlanFile)
                    zoneObj.floorPlan = "$file1";
                var selector = new QueryParam().str("id", id).obj("zone", zoneObj, Struct.zone);
                var str = queryName + selector.get() + fullFloorObj;
                if (floorPlanFile){
                    return toApolloMutationWithFile(str, floorPlanFile).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
                } else {
                    return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
                }
            },
            assignTenantToFloor: function(id, domain){
                var queryName = 'assignTenantToZone';
                var selector = new QueryParam().str("id", id).str("domain", domain);
                var str = queryName + selector.get() + "{ id }";
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            deleteFloor: function(id){
                var queryName = 'deleteZone';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            duplicateFloor: function(buildingId, domain, name, floorPlanFile, locations){
                var queryName = 'duplicateZone';
                var selector = new QueryParam().obj("input", {
                    name: name,
                    buildingId: buildingId,
                    dns: domain,
                    floorPlan: "$file1",
                    locations: locations
                }, Struct.duplicateZoneInput);
                var str = queryName + selector.get() + "{ id }";
                return toApolloMutationWithFile(str, floorPlanFile).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getHolidays: function(date){
                var queryName = 'getTimetableHolidays';
                var selector = new QueryParam().str("date", date);
                var str = queryName + selector.get() + timetableHolidayObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getHubOnlineLog: function(id, startTime){
                var queryName = 'getOfflineEvents';
                var selector = new QueryParam().obj("input", {
                    id: id,
                    startTime, startTime,
                    type: "HUB",
                }, Struct.offlineEvent);
                var str = queryName + selector.get() + offlineEventObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getGatewayOnlineLog: function(id, startTime){
                var queryName = 'getOfflineEvents';
                var selector = new QueryParam().obj("input", {
                    id: id,
                    startTime, startTime,
                    type: APIKEY.applicationType.gateway,
                }, Struct.offlineEvent);
                var str = queryName + selector.get() + offlineEventObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getDeviceOnlineLog: function(id, startTime){
                var queryName = 'getOfflineEvents';
                var selector = new QueryParam().obj("input", {
                    id: id,
                    startTime, startTime,
                    type: "DEVICE",
                }, Struct.offlineEvent);
                var str = queryName + selector.get() + offlineEventObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            addOAuthBinding: function(tenantId, code, provider) {
                var queryName = "addOAuthBinding";
                var selector = new QueryParam().str("id", tenantId).str("code", code).enum("provider", provider);
                var str = queryName + selector.get() + tenantOAuthObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            removeOAuthBinding: function(tenantId, code) {
                var queryName = "removeOAuthBinding";
                var selector = new QueryParam().str("id", tenantId).str("code", code);
                var str = queryName + selector.get() + tenantOAuthObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getRecapQueue: function(queueName){
              var queryName = 'amqpStatus';
              var selector = new QueryParam().str("queueName", queueName);
              var str = queryName + selector.get() + queueStatusObj;
              return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getMeters: function(tenantId){
                var queryName = 'queryMeter';
                var selector = new QueryParam().str("tenantId", tenantId).val("limit", 5000);
                var str = queryName + selector.get() + meterWithoutDpObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getWaterMeters: function(tenantId){
                var queryName = 'queryWaterMeter';
                var selector = new QueryParam().str("tenantId", tenantId).val("limit", 5000);
                var str = queryName + selector.get() + waterMeterWithoutDpObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getMeter: function(meterId){
                var queryName = 'getMeter';
                var selector = new QueryParam().str("id", meterId);
                var str = queryName + selector.get() + meterObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getWaterMeter: function(meterId){
                var queryName = 'getWaterMeter';
                var selector = new QueryParam().str("id", meterId);
                var str = queryName + selector.get() + waterMeterObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            createMeter: function(tenantId, serial, status, description, hasKva, dataflowMode, dataType, meterType, xmlUrl, bmsType, dataTransmissionUnit, dataTopic, heartbeatTopic, payloadParser, tariffId, remark){
                var queryName = "createMeter";
                var inputObj = {
                  tenantId: tenantId,
                  serial: serial,
                  status: status,
                  description: description,
                  hasKva: hasKva,
                  dataflowMode: dataflowMode,
                  dataType: dataType,
                  meterType: meterType,
                  tariffId: tariffId,
                  remark: remark
                }
                if (meterType === "EGAUGE") {
                  inputObj.egaugeMeter = { xmlUrl: xmlUrl };
                } else if (meterType === "BMS") {
                  inputObj.bmsMeter = { type: bmsType, dataTransmissionUnit: dataTransmissionUnit };
                } else if (meterType === "ACREL") {
                  inputObj.acrelMeter = { dataTopic: dataTopic, heartbeatTopic: heartbeatTopic, payloadParser: payloadParser };
                }
                var selector = new QueryParam().obj("meter", inputObj, Struct.meter);
                var str = queryName + selector.get() + meterObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            createWaterMeter: function(waterMeterParams){
                var queryName = "createWaterMeter";
                var selector = new QueryParam().obj("meter", waterMeterParams,Struct.waterMeter);
                var str = queryName + selector.get() + waterMeterObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            updateMeter: function(id, tenantId, serial, status, description, hasKva, dataflowMode, dataType, meterType, xmlUrl, bmsType, dataTransmissionUnit, dataTopic, heartbeatTopic, payloadParser, tariffId, remark){
                var queryName = "updateMeter";
                var inputObj = {
                  tenantId: tenantId,
                  serial: serial,
                  status: status,
                  description: description,
                  hasKva: hasKva,
                  dataflowMode: dataflowMode,
                  dataType: dataType,
                  meterType: meterType,
                  remark: remark,
                  tariffId: tariffId
                }
                if (meterType === "EGAUGE") {
                  inputObj.egaugeMeter = { xmlUrl: xmlUrl };
                } else if (meterType === "BMS") {
                  inputObj.bmsMeter = { type: bmsType, dataTransmissionUnit, dataTransmissionUnit };
                } else if (meterType === "ACREL") {
                  inputObj.acrelMeter = { dataTopic: dataTopic, heartbeatTopic: heartbeatTopic, payloadParser: payloadParser };
                }
                var selector = new QueryParam().str("id", id).obj("meter", inputObj, Struct.meter);
                var str = queryName + selector.get() + meterObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            updateWaterMeter: function(id,waterMeterParams){
                var queryName = "updateWaterMeter";
                var selector = new QueryParam().str("id", id).obj("meter", waterMeterParams, Struct.waterMeter);
                var str = queryName + selector.get() + waterMeterObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            deleteMeter: function(id){
                var queryName = "deleteMeter";
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            deleteWaterMeter: function(id){
                var queryName = "deleteWaterMeter";
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            uploadMeterData: function(serial, timezoneOffset, file) {
                var queryName = 'energyMeterDataUpload';
                var selector = new QueryParam().str("serial", serial).val("timezoneOffset", timezoneOffset).val("filepath", "$file1");
                var str = queryName + selector.get();
                return toApolloMutationWithFile(str, file).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            uploadWaterMeterData: function(serial, timezoneOffset, file) {
                var queryName = 'waterMeterDataUpload';
                var selector = new QueryParam().str("serial", serial).val("timezoneOffset", timezoneOffset).val("filepath", "$file1");
                var str = queryName + selector.get();
                return toApolloMutationWithFile(str, file).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            recapMeter: function(tenantId, meterId, start, end) {
                var queryName = 'recaptureEnergyData';
                var selector = new QueryParam().str("tenantId", tenantId).val("startTime", start).val("endTime", end);
                if (meterId)
                    selector.arr("meterIds", [meterId], "str");
                var str = queryName + selector.get();
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            recaptureWaterMeter: function(tenantId, meterId, start, end) {
                var queryName = 'recaptureWaterData';
                var selector = new QueryParam().str("tenantId", tenantId).val("startTime", start).val("endTime", end);
                if (meterId)
                    selector.arr("meterIds", [meterId], "str");
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            createEguageDataPoints: function(meterId){
                var queryName = "getDatapointFromMeter";
                var selector = new QueryParam().str("meterId", meterId);
                var str = queryName + selector.get();
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            createDataPoint: function(meterId, status, name, scaler, scalerInHub, isGenerator){
                var queryName = "createDatapoint";
                var selector = new QueryParam().obj("datapoint", {
                    meterId: meterId,
                    status: status,
                    name: name,
                    scaler: scaler,
                    scalerInHub: scalerInHub,
                    isGenerator: isGenerator,
                }, Struct.dataPoint);
                var str = queryName + selector.get() + dataPointObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            createWaterDatapoint: function(params){
                var queryName = "createWaterDatapoint";
                var selector = new QueryParam().obj("datapoint", params, Struct.waterDataPoint);
                var str = queryName + selector.get() + dataWaterPointObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            // queryWaterDatapoint:function(id){
            //     var queryName = "queryWaterDatapoint";
            //     var selector = new QueryParam().str("meterId", id);
            //     var str = queryName + selector.get() + dataWaterPointObj;
            //     return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            // },
            updateDataPoint: function(id, name, scaler, status){
                var queryName = "updateDatapoint";
                var selector = new QueryParam().str("id", id).obj("datapoint", {
                    name: name,
                    scaler: scaler,
                    status: status
                }, Struct.dataPoint);
                var str = queryName + selector.get() + dataPointObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            updateWaterDatapoint: function(id, params){
                var queryName = "updateWaterDatapoint";
                var selector = new QueryParam().str("id", id).obj("datapoint", params, Struct.waterDataPoint);
                var str = queryName + selector.get() + dataWaterPointObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            deleteDataPoint: function(id){
                var queryName = "deleteDatapoint";
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            deleteWaterDatapoint: function(id){
                var queryName = "deleteWaterDatapoint";
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            createVirtualDataPoint: function(tenantId, name, expression, status){
                var queryName = "createVirtualDatapoint";
                var selector = new QueryParam().obj("virtualDatapoint", {
                    tenantId: tenantId,
                    status: status,
                    name: name,
                    expression: expression
                }, Struct.virtualDataPoint);
                var str = queryName + selector.get() + virtualDataPointObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            updateVirtualDataPoint: function(id, name, expression, status){
                var queryName = "updateVirtualDatapoint";
                var selector = new QueryParam().str("id", id).obj("virtualDatapoint", {
                    name: name,
                    expression: expression,
                    status: status
                }, Struct.virtualDataPoint);
                var str = queryName + selector.get() + virtualDataPointObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            deleteVirtualDataPoint: function(id){
                var queryName = "deleteVirtualDatapoint";
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            createWaterVirtualDataPoint: function(tenantId, name, expression, status){
                var queryName = "createWaterVirtualDatapoint";
                var selector = new QueryParam().obj("virtualDatapoint", {
                    tenantId: tenantId,
                    status: status,
                    name: name,
                    expression: expression
                }, Struct.virtualDataPoint);
                var str = queryName + selector.get() + waterVirtualDataPointObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            updateWaterVirtualDataPoint: function(id, name, expression, status){
                var queryName = "updateWaterVirtualDatapoint";
                var selector = new QueryParam().str("id", id).obj("virtualDatapoint", {
                    name: name,
                    expression: expression,
                    status: status
                }, Struct.virtualDataPoint);
                var str = queryName + selector.get() + waterVirtualDataPointObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            deleteWaterVirtualDataPoint: function(id){
                var queryName = "deleteWaterVirtualDatapoint";
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getRawEnergyData: function(dataPointId, startTime, endTime){
                var queryName = 'getRawEnergyData';
                var selector = new QueryParam().val("startTime", startTime).val("endTime", endTime).str("datapointId", dataPointId).enum("interval", "HOUR").enum("method", "SUM").val("noDiff", false);
                var str = queryName + selector.get() + dataPointDataObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getRawWaterData: function(dataPointId, startTime, endTime){
                var queryName = 'getRawWaterData';
                var selector = new QueryParam().val("startTime", startTime).val("endTime", endTime).str("datapointId", dataPointId).enum("interval", "HOUR").enum("method", "SUM").val("noDiff", false);
                var str = queryName + selector.get() + dataPointDataObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getHourRawEnergyData: function(dataPointId, startTime, endTime){
                var queryName = 'getRawEnergyData';
                var selector = new QueryParam().val("startTime", startTime).val("endTime", endTime).str("datapointId", dataPointId).enum("interval", "MINUTE").enum("method", "SUM").val("noDiff", true);
                var str = queryName + selector.get() + dataPointDataObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getHourRawWaterData: function(dataPointId, startTime, endTime){
                var queryName = 'getRawWaterData';
                var selector = new QueryParam().val("startTime", startTime).val("endTime", endTime).str("datapointId", dataPointId).enum("interval", "MINUTE").enum("method", "SUM").val("noDiff", true);
                var str = queryName + selector.get() + dataPointDataObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getHierarchy: function(id, type) {
                var queryName = 'expandHierarchyNode';
                var selector = new QueryParam().obj("input", {
                  id: id,
                  type: type
                }, Struct.hierarchyInput);
                var str = queryName + selector.get() + hierarchyObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getHierarchyNodeLocations: function(id, type) {
                var queryName = 'queryHierarchyNodeLocations';
                var selector = new QueryParam().obj("input", {
                    id: id,
                    type: type
                }, Struct.hierarchyInput);
                var str = queryName + selector.get() + hierarchyObjLocations;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getHierarchyDataPointByRefId: function(refId) {
              var queryName = 'getHierarchyDataPointByRefId';
                var selector = new QueryParam().str("refId", refId);
                var str = queryName + selector.get() + hierarchyDataPointObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getDataPoint: function(id) {
                var queryName = 'getDatapoint';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get() + dataPointObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getWaterDataPoint: function(id) {
                var queryName = 'getWaterDatapoint';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get() + dataWaterPointObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getVirtualDataPoint: function(id) {
                var queryName = 'getVirtualDatapoint';
                var selector = new QueryParam().str("virtualDatapointId", id);
                var str = queryName + selector.get() + virtualDataPointObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getVirtualWaterDataPoint: function(id) {
                var queryName = 'getWaterVirtualDatapoint';
                var selector = new QueryParam().str("virtualDatapointId", id);
                var str = queryName + selector.get() + waterVirtualDataPointObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getVirtualDataPoints: function(tenantId, limit, offset) {
                var queryName = 'queryVirtualDatapoint';
                var selector = new QueryParam().str("tenantId", tenantId).val("limit", limit).val("offset", offset);
                var str = queryName + selector.get() + virtualDataPointObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getWaterVirtualDataPoints: function(tenantId, limit, offset) {
                var queryName = 'queryWaterVirtualDatapoint';
                var selector = new QueryParam().str("tenantId", tenantId).val("limit", limit).val("offset", offset);
                var str = queryName + selector.get() + waterVirtualDataPointObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getConsumption: function(dpIds, dataType, period, startTime, endTime) {
                var queryName = 'getConsumption';
                var selector = new QueryParam().arr("datapointIds", dpIds, "str").val("timezoneOffsiteMinute", (new Date().getTimezoneOffset() * -1)).enum("timePeriod", period);
                if (period === "RANGE") {
                  selector.val("startTime", startTime).val("endTime", endTime).enum("pellet", "PELLET_HOUR");
                } else {
                  selector.enum("pellet", "PELLET_DAY");
                }
                if (dataType === "D_ENERGY") {
                  selector.enum("source", "ENERGY").val("isVirtual", false);
                } else if (dataType === "D_VIRTUAL_DATA_POINT") {
                  selector.enum("source", "ENERGY").val("isVirtual", true);
                } else if (dataType === "D_WATER_METER") {
                  selector.enum("source", "WATER").val("isVirtual", false);
                } else if (dataType === "D_WATER_VIRTUAL_DATA_POINT") {
                  selector.enum("source", "WATER").val("isVirtual", true);
                } else if (dataType === "D_TEMPERATURE") {
                  selector.enum("source", "TEMPERATURE").val("isVirtual", false);
                }
                var str = queryName + selector.get() + consumptionObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            testFormula: function(expression) {
                var queryName = 'testFormula';
                var selector = new QueryParam().str("expression", expression);
                var str = queryName + selector.get() + testFormulaObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            testWaterFormula: function(expression) {
                var queryName = 'testWaterFormula';
                var selector = new QueryParam().str("expression", expression);
                var str = queryName + selector.get() + testFormulaObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            
            recalcPovSummary: function(povId, startTime, endTime) {
                var queryName = 'reSummaryByPov';
                var selector = new QueryParam().str("povId", povId).val("startTime", startTime).val("endTime", endTime);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            calcChildPovs: function(id, type, startTime, endTime) {
                var queryName = 'reCalculateNodeData';
                var selector = new QueryParam().str("id", id).enum("type", type).val("startTime", startTime).val("endTime", endTime);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            bindDataPointToHierarchy: function(name, dataId, isVirtual, type, povId, parentType){
                var queryName = "bindHierarchyDataPoint";
                var dType = "D_ENERGY";
                if (type === "WATER") {
                  dType = isVirtual ? "D_WATER_VIRTUAL_DATA_POINT" : "D_WATER_METER";
                } else if (type === "ENERGY") {
                  dType = isVirtual ? "D_VIRTUAL_DATA_POINT" : "D_ENERGY";
                } else if (type === "TEMPERATURE") {
                  dType = "D_TEMPERATURE";
                }
                var selector = new QueryParam().obj("input", {
                    name: name,
                    dataId: dataId,
                    dataType: dType,
                    parent: {
                      id: povId,
                      type: parentType
                    }
                }, Struct.bindDataPointInput);
                var str = queryName + selector.get() + hierarchyDataPointObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            unbindDataPointFromHierarchy: function(id, pId, pType){
                var queryName = "unbindHierarchyDataPoint";
                var selector = new QueryParam().str("id", id).obj("parent", {
                    id: pId,
                    type: pType
                }, Struct.unbindDataPointParent);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getHierarchyPov: function(id) {
                var queryName = 'getHierarchyPov';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get() + hierarchyPovObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            createHierarchyPov: function(name, parentId, parentType, accessType){
                var queryName = "createHierarchyPov";
                var selector = new QueryParam().obj("input", {
                    name: name,
                    accessType: accessType,
                    parent: {
                      id: parentId,
                      type: parentType
                    },
                }, Struct.createChildInput);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            updateHierarchyPov: function(id, name, povUnit, visible, accessType, zoneReference){
                var queryName = "updateHierarchyPov";
                var selector = new QueryParam().str("id", id).obj("input", {
                    name: name,
                    povUnit: povUnit,
                    visible: visible,
                    accessType: accessType,
                    zoneReference: zoneReference,
                }, Struct.updateHierarchyPovInput);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            deleteHierarchyPov: function(id) {
                var queryName = "deleteHierarchyPov";
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getHierarchySite: function(id) {
                var queryName = 'getHierarchySite';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get() + hierarchySiteObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            createHierarchySite: function(name, parentId, parentType, cityId, address, nodeReferenceId, accessType){
                var queryName = "createHierarchySite";
                var selector = new QueryParam().obj("input", {
                    name: name,
                    cityId: cityId,
                    address: address,
                    nodeReferenceId: nodeReferenceId,
                    accessType: accessType,
                    parent: {
                      id: parentId,
                      type: parentType
                    },
                }, Struct.createChildInput);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            updateHierarchySite: function(id, name, cityId, address, nodeReferenceId, accessType){
                var queryName = "updateHierarchySite";
                var selector = new QueryParam().str("id", id).str("name", name).str("cityId", cityId)
                    .str("address", address).str("nodeReferenceId", nodeReferenceId).enum("accessType", accessType);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            deleteHierarchySite: function(id) {
                var queryName = "deleteHierarchySite";
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            updateHierarchyNodeName: function(id, type, name) {
                var queryName = "updateHierarchyNodeName";
                var selector = new QueryParam().obj("input", {
                  name: name,
                  id: id,
                  type: type
                }, Struct.nodeNameInput);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getTenantPov: function(tenantId) {
              var queryName = "getHierarchyPovsByTenant";
              var selector = new QueryParam().str("tenantId", tenantId);
              var str = queryName + selector.get() + simplePovObj;
              return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getOccupiedAreas: function(tenantId) {
              var queryName = "queryAreasByTenant";
              var selector = new QueryParam().str("tenantId", tenantId);
              var str = queryName + selector.get() + occupiedAreaAndDateObj;
              return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getOccupiedAreaHistory: function(id) {
              var queryName = "queryEffectiveDateByOccupiedArea";
              var selector = new QueryParam().str("occupiedAreaId", id);
              var str = queryName + selector.get() + occupiedAreaDateObj;
              return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            uploadOccupiedAreas: function(tenantId, file){
                var queryName = 'occupiedAreaBatchUpload';
                var selector = new QueryParam().str("tenantId", tenantId).val("filepath", "$file1");
                var str = queryName + selector.get();
                return toApolloMutationWithFile(str, file).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            createOccupiedArea: function(name, tenantId, remark) {
              var queryName = "createOccupiedArea";
              var selector = new QueryParam().str("name", name).str("tenantId", tenantId).str("remark", remark);
              var str = queryName + selector.get() + occupiedAreaObj;
              return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            updateOccupiedArea: function(id, name, remark) {
              var queryName = "updateOccupiedArea";
              var selector = new QueryParam().str("id", id).str("name", name).str("remark", remark);
              var str = queryName + selector.get() + occupiedAreaObj;
              return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            deleteOccupiedArea: function(id) {
              var queryName = "deleteOccupiedArea";
              var selector = new QueryParam().str("id", id);
              var str = queryName + selector.get();
              return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            createOccupiedAreaEffectiveDate: function(occupiedAreaId, area, effectiveDate) {
              var queryName = "createOccupiedAreaEffectiveDate";
              var selector = new QueryParam().str("occupiedAreaId", occupiedAreaId).val("area", area).val("effectiveDate", effectiveDate);
              var str = queryName + selector.get() + occupiedAreaDateObj;
              return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            deleteOccupiedAreaEffectiveDate: function(id) {
              var queryName = "deleteOccupiedAreaEffectiveDate";
              var selector = new QueryParam().str("id", id);
              var str = queryName + selector.get();
              return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            bindOccupiedAreaToPov: function(id, povId) {
              var queryName = "occupiedAreaBindPov";
              var selector = new QueryParam().str("id", id).str("povId", povId);
              var str = queryName + selector.get();
              return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getHubs: function(isSimple){
                var queryName = 'getHubs';
                if (isSimple) {
                  var str = queryName + simpleHubObj;
                  return toApolloQuery(str, simpleHubFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
                } else {
                  var str = queryName + hubBuildingObj;
                  return toApolloQuery(str, hubBuildingFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
                }
            },

            updateHub: function(id, buildingIds, enabled, remark){
                var queryName = "updateHub";
                var selector = new QueryParam().str("id", id).obj("hub", {
                    buildings: buildingIds,
                    remark: remark,
                    enabled: enabled,
                }, Struct.hub);
                var str = queryName + selector.get() + hubBuildingObj;
                return toApolloMutation(str, hubBuildingFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            deleteHub: function(id){
                var queryName = "deleteHub";
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            createVirtualHub: function(){
                var queryName = "createVirtualHub";
                var str = queryName;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getHubTags: function(program){
                var queryName = "releaseList";
                var selector = new QueryParam().val("limit", 50).val("offset", 0).enum("program", program);
                var str = queryName + selector.get() + "{ tag }";
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            restartHub: function(id, program) {
                var queryName = "restart";
                var selector = new QueryParam().str("id", id).enum("program", program);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            updateHubVersion: function(id, tag, program) {
                var queryName = "updateVersion";
                var selector = new QueryParam().str("id", id).str("tag", tag).enum("program", program);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getSLGateways: function(){//TODOricky
                // var queryName = '';
                // var str = queryName + ;
                // return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            createSLGateways: function(){
                var queryName = "createSmartlightServer";
                var str = queryName;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getGateway: function(id){
                var queryName = 'getGateway';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get() + fullGatewayObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getGatewaysByBuildingId: function(id){
                var queryName = 'getBuilding';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get() + buildingGatewayObj;
                return toApolloQuery(str, hubGatewayFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getGateways: function(displayOnly){
                var queryName = 'getGateways';
                var str = queryName + (displayOnly ? displayGatewayObj : simpleGatewayObj);
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            updateGateway: function(id, enabled, remark){
                var queryName = 'updateGateway';
                var selector = new QueryParam().str("id", id).obj("gateway", {
                    remark: remark,
                    enabled: enabled
                }, Struct.gateway);
                var str = queryName + selector.get() + simpleGatewayObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            deleteGateway: function(id){
                var queryName = 'deleteGateway';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            backupGateway: function(id){
                var queryName = 'backupGateway';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get() + simpleGatewayObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            restoreGateway: function(id, fromId, version){
                var queryName = 'restoreGateway';
                var selector = new QueryParam().str("id", id).str("version", version).str("fromGatewayId", fromId)
                var str = queryName + selector.get() + "{ id }";
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getGatewayBackups: function(provider){
                var queryName = 'getGatewayBackups';
                var selector = new QueryParam().str("provider", provider);
                var str = queryName + selector.get() + gatewayBackupObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            createDeviceLoc: function(deviceId, zoneId, x, y){
                var queryName = 'createLocation';
                var selector = new QueryParam().str("deviceId", deviceId).str("zoneId", zoneId).obj("location", {
                    x: x,
                    y: y
                }, Struct.location);
                var str = queryName + selector.get() + locationObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            updateDeviceLoc: function(id, x, y){
                var queryName = 'updateLocation';
                var selector = new QueryParam().str("id", id).obj("location", {
                    x: x,
                    y: y
                }, Struct.location);
                var str = queryName + selector.get() + locationObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            deleteDeviceLoc: function(id){
                var queryName = 'deleteLocation';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            //if dont give zoneId, response's location will be null
            updateDeviceInfo: function(id, applicationType, defaultTemperature, defaultDimLv, fanSpeed, fanSpeedProfileId, remark, zoneId){
                var queryName = 'landlordUpdateDevice';
                var selector = new QueryParam().str("id", id).obj("device", {
                    applicationType: applicationType,
                    defaultTemperature: defaultTemperature,
                    defaultDimming: defaultDimLv,
                    remark: remark,
                    defaultFanSpeed: fanSpeed,
                    fanSpeedProfileId: fanSpeedProfileId,
                }, Struct.deviceInfo);
                if (zoneId)
                    selector.str("zoneId", zoneId);
                var str = queryName + selector.get() + detailDeviceObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            createDevice: function(serial, gatewayId, provider, deviceType, applicationType){
                var queryName = 'createDeviceManually';
                var selector = new QueryParam().str("serial", serial).str("gatewayId", gatewayId).enum("deviceType", deviceType).enum("provider", provider).enum("applicationType", applicationType);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            normalDevice: function(id) {
                var queryName = 'normalDevice';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            setGatewayLoc: function(gatewayId, zoneId, x, y) {
                var queryName = "setGatewayLocation";
                var selector = new QueryParam().str("gatewayId", gatewayId).str("zoneId", zoneId).obj("gateway", {
                    x: x,
                    y: y
                }, Struct.location);
                var str = queryName + selector.get() + gatewayLocObj;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            deleteGatewayLoc: function(gatewayId, zoneId){
                var queryName = 'deleteGatewayLocation';
                var selector = new QueryParam().str("gatewayId", gatewayId).str("zoneId", zoneId);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getFanSpeedProfiles: function(){
                var queryName = "queryAllFanSpeedProfile";
                var str = queryName + fanSpeedProfileObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            performDeviceAction: function(id, action){
                var queryName = 'toggleDevice';
                var selector = new QueryParam().str("id", id).enum("state", action);
                var str = queryName + selector.get() + "{ id }";
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getControlProfile: function(id) {
                var queryName = "getControlProfile";
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get() + controlProfileObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            updateControlProfile: function(id, tenantId, name, learnFromRequest, warmerBiasApplied, requestDuration) {
                var queryName = 'updateControlProfile';
                var selector = new QueryParam().str("id", id).str("tenantId", tenantId).str("controlProfileName", name).val("learnFromRequest", learnFromRequest).val("warmerBiasApplied", warmerBiasApplied).val("requestDuration", requestDuration);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getSensorData: function(id, forWorkstation){
                var queryName = "getEnvironmental";
                var selector = new QueryParam().str("id", id).enum("filter", (forWorkstation ? APIKEY.nodeType.workstation : "DEVICE"));
                var str = queryName + selector.get() + sensorDataObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getLightSensorLog: function(id, start, end){
                var queryName = "getLightRawData";
                var selector = new QueryParam().str("deviceId", id).val("startTime", start).val("endTime", end);
                var str = queryName + selector.get() + lightSensorDataObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getTemperatureSensorLog: function(ids, start, end, pellet){
                var queryName = "getTemperatureRawData";
                pellet = pellet ? pellet : "PELLET_RAW";
                ids = typeof ids === "string" ? [ids] : ids;
                var selector = new QueryParam().arr("deviceIds", ids, "str").val("startTime", start).val("endTime", end).enum("pellet", pellet).val("timezoneOffsiteMinute", (new Date().getTimezoneOffset() * -1));
                var str = queryName + selector.get() + temperatureSensorDataObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getIaqLog: function(serial, startTime, endTime){
                var queryName = "getIaqRawData";
                var selector = new QueryParam().str("serial", serial).val("startTime", startTime).val("endTime", endTime);
                var str = queryName + selector.get() + iaqInfoObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getDeviceLog: function(id, startTime, endTime, limit, showStatusIdent){
                var queryName = "getDeviceLog";
                var selector = new QueryParam().obj("input", {
                    id: id,
                    startTime: startTime,
                    endTime: endTime,
                    by: "DEVICE",
                    limit: limit,
                    statusIdent: showStatusIdent
                }, Struct.deviceEvent);
                var str = queryName + selector.get() + deviceLogObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getEnergyMeterLog: function(povId, startTime, endTime){//device serial is povId
                var queryName = "summaryKwh";
                var selector = new QueryParam().str("povId", povId).enum("summaryIntervalType", "SUMMARY_DAY").val("startTime", startTime).val("endTime", endTime);
                var str = queryName + selector.get() + energyMeterLogObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getDevicesActivitiesByZone: function(zoneId, startTime, endTime){
                var queryName = "exportDevicesActivity";
                var selector = new QueryParam().str("id", zoneId).enum("by", "ZONE").val("startTime", startTime).val("endTime", endTime);
                var str = queryName + selector.get() + deviceActivityObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            getDevicesOnlineLogsByZone: function(zoneId, startTime, endTime){
                var queryName = "exportDevicesOnline";
                var selector = new QueryParam().str("id", zoneId).enum("by", "ZONE").val("startTime", startTime).val("endTime", endTime);
                var str = queryName + selector.get() + deviceOnlineObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getDeviceDetails: function(id){
                var queryName = "getDevice";
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get() + devicePageObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            searchDevice: function(serial) {
                var queryName = "queryDevice";
                var selector = new QueryParam().str("serial", serial);
                var str = queryName + selector.get() + "{ id serialId }";
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getDeviceByApplicationType: function(tenantId, applicationTypes){
                var queryName = "getDevicesByTenantByApplicationType";
                var selector = new QueryParam().str("tenantId", tenantId).arr("applicationTypes", applicationTypes, "str");
                var str = queryName + selector.get() + "{ id serial applicationType }";
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getAllTimeslots: function(dayOfWeek){
                var queryName = "getAllTimeslots";
                var selector = new QueryParam().enum("weekday", dayOfWeek);
                var str = queryName + selector.get() + timeslotFullObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            enforceTimetable: function(id) {
                var queryName = 'enforceTimetable';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getLandlordUsers: function(landlordId){
                var queryName = 'getProfiles';
                var selector = new QueryParam().obj("query", {
                    id: landlordId,
                    type: "LANDLORD",
                }, Struct.profileQuery);
                var str = queryName + selector.get() + simpleUserWithTenantObj;
                return toApolloQuery(str, simpleUserFrag, simpleTenantFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getAllUsers: function(forDownload){
                var queryName = 'getAllProfiles';
                if (forDownload) {
                  var str = queryName + simpleUserForDownloadObj;
                  return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
                } else {
                  var str = queryName + simpleUserWithTenantObj;
                  return toApolloQuery(str, simpleUserFrag, simpleTenantFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
                }
            },

            getTenantAdmin: function(tenantId){
                var queryName = 'getProfiles';
                var selector = new QueryParam().obj("query", {
                    id: tenantId,
                    type: "TENANT",
                    adminOnly: true,
                }, Struct.profileQuery);
                var str = queryName + selector.get() + simpleUserObj;
                return toApolloQuery(str, simpleUserFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            createTenantAdmin: function(tenantId, email){
                var queryName = 'createTenantUserV2';
                var selector = new QueryParam().str("tenantId", tenantId).str("email", email).arr("aclRoles", [APIKEY.aclRole.tenantAdmin], "enum").val("invite", true).obj("options", {}, {});
                var str = queryName + selector.get() + simpleUserObj;
                return toApolloMutation(str, simpleUserFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            createLandlordUser: function(landlordId, email, roles){
                var queryName = 'createLandlordUserV2';
                var selector = new QueryParam().str("landlordId", landlordId).str("email", email).arr("aclRoles", roles, "enum").val("invite", true);
                var data = {};
                selector.obj("options", data, Struct.landlord);
                var str = queryName + selector.get() + simpleUserWithTenantObj;
                return toApolloMutation(str, simpleUserFrag, simpleTenantFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            updateLandlordUser: function(id, roles){
                var queryName = 'updateProfile';
                var selector = new QueryParam().str("id", id);
                selector.obj("profile", {
                    aclRoles: roles
                }, Struct.landlord);
                var str = queryName + selector.get() + simpleUserWithTenantObj;
                return toApolloMutation(str, simpleUserFrag, simpleTenantFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            deleteProfile: function(id){
                var queryName = 'deleteProfile';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            inviteUser: function(id){
                var queryName = 'inviteUserV2';
                var selector = new QueryParam().str("id", id).str("page", "landlord");
                var str = queryName + selector.get() + "{ inviteStatusV2 }";
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            changeProfileLandlord: function(landlordId){
                var queryName = 'updateLandlord';
                var selector = new QueryParam().str("landlordId", landlordId);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getCurrentUser: function(){
                var queryName = 'myProfile';
                var str = queryName + currentUserObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getSetRoleOptions: function(){
                var queryName = "getSetAclRoleOptions";
                var str = queryName + roleOptionObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getLandlords: function(){
                var queryName = 'getLandlords';
                var str = queryName + simpleLandlordObj;
                return toApolloQuery(str, simpleLandlordFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            createLandlord: function(name){
                var queryName = 'createLandlord';
                var selector = new QueryParam().str("name", name);
                var str = queryName + selector.get() + simpleLandlordObj;
                return toApolloMutation(str, simpleLandlordFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
            deleteLandlord: function(id){
                var queryName = 'deleteLandlord';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get();
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getLandlord: function(id){
                var queryName = 'getLandlord';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get() + distributorDetailPageObj;
                return toApolloQuery(str, simpleLandlordFrag, simpleUserFrag, simpleTenantFrag, simpleCityFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            scanGateways: function(hubId){
                var queryName = 'scanGateways';
                var selector = new QueryParam().str("hubId", hubId).val("listOnly", false);
                var str = queryName + selector.get() + "{ success }";
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            scanDevices: function(gatewayId, registerOnly){
                var queryName = 'scanDevices';
                var selector = new QueryParam().str("gatewayId", gatewayId).val("listOnly", registerOnly);
                var str = queryName + selector.get() + "{ success }";
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            unpairDevice: function(id){
                var queryName = 'unpairDevice';
                var selector = new QueryParam().str("id", id);
                var str = queryName + selector.get() + '';
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            getTariffs: function(limit, offset){
                var queryName = 'listTariff';
                var selector = new QueryParam().val("limit", limit || 99999).val("offset", offset || 0);
                var str = queryName + selector.get() + tariffObj;
                return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            createTariff: function(name, currency, remark){
                var queryName = 'createTariff';
                var selector = new QueryParam().str("name", name).str("currency", currency).str("remark", remark);
                var str = queryName + selector.get() + '';
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            updateTariff: function(id, name, currency, remark){
                var queryName = 'updateTariff';
                var selector = new QueryParam().str("tariffId", id).str("name", name).str("currency", currency).str("remark", remark);
                var str = queryName + selector.get() + '';
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },

            deleteTariff: function(id){
                var queryName = 'deleteTariff';
                var selector = new QueryParam().str("tariffId", id);
                var str = queryName + selector.get() + '';
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            },
        }
    }

})();
